import { IconButton, Typography } from "@mui/material"
import { useSelector } from "react-redux"
import AccountBoxIcon from "@mui/icons-material/AccountBox"

export const Header = (props) => {
	const saveId = props.saveId
	const campaignStats = props.campaignStats
	const setDrawerOpen = props.setDrawerOpen
	const stats = useSelector((state) => state.persistentReducer.data.getIn([saveId, "stats"]))
	if (!stats) {
		return null
	}
	return (
		<div style={{ height: "10vh", display: "flex", alignItems: "stretch", flexDirection: "row" }}>
			<div style={{ flex: 30, alignItems: 'center', display: 'flex' }}>
				<IconButton color="primary" size="small" onClick={() => setDrawerOpen(true)}>
					<AccountBoxIcon fontSize="inherit" sx={{ color: "white", fontSize: "5vh" }} />
				</IconButton>
			</div>
			<div style={{ flex: 70, flexDirection: "row", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
				{campaignStats.map((campaignStat) => {
					if (!campaignStat.hidden){
						const currentValue = stats.getIn([campaignStat.id, "value"])
						return (
							<div key={campaignStat.id} style={{ flexDirection: "row", display: "flex", marginRight: 10, alignItems: "center" }}>
								<Typography sx={{ color: "white", fontSize: "2vh" }}>{`${campaignStat.name}: ${currentValue}${campaignStat.max ? "/" + campaignStat.max : ""}`}</Typography>
								{campaignStat.icon && <img style={{ height: "2vh", marginLeft: 3 }} src={campaignStat.icon} alt="Logo" />}
							</div>
						)
					} else {
						return null;
					}
				})}
			</div>
		</div>
	)
}
