const START_GAME = 'START_GAME';
const STORE_IN_CACHE = 'STORE_IN_CACHE';
const STORE_CAMPAIGNS = 'STORE_CAMPAIGNS';
const STORE_CAMPAIGN = 'STORE_CAMPAIGN';
const SET_STAT_VALUE = 'SET_STAT_VALUE';
const SET_STAT_MESSAGE_SHOWN = 'SET_STAT_MESSAGE_SHOWN';
const ADD_MESSAGE = 'ADD_MESSAGE';
const ADD_UNLOCKED_KEYWORD = 'ADD_UNLOCKED_KEYWORD';
const REMOVE_UNLOCKED_KEYWORD = 'REMOVE_UNLOCKED_KEYWORD';

export const ACTION_IDS = {
    START_GAME,
    STORE_IN_CACHE,
    STORE_CAMPAIGNS,
    STORE_CAMPAIGN,
    SET_STAT_VALUE,
    SET_STAT_MESSAGE_SHOWN,
    ADD_MESSAGE,
    ADD_UNLOCKED_KEYWORD,
    REMOVE_UNLOCKED_KEYWORD
}