import {Map, List} from 'immutable';
import { ACTION_IDS } from '../actions/actionTypes';

const initialState = {
    data: Map(),
}

const persistentReducer = (state = initialState, action) => {
    let newData = state.data;
    switch(action.type){
        case ACTION_IDS.START_GAME: {
            const time = Date.now();
            let newGameData = Map({
                id: action.gameId,
                time,
                scenarioId: action.scenarioData.id,
                charachterId: action.charachterId,
                unlockedKeywords: List(),
                stats: Map(),
                messages: List(),
            });
            const scenario = action.scenarioData;
            const char = scenario.charachters.find((currentChar) => currentChar.id === action.charachterId);
            newGameData = newGameData.set('title', scenario.title);
            newGameData = newGameData.set('char', char.label);
            char.stats.forEach(stat => {
                newGameData = newGameData.setIn(['stats', stat.id], Map({value: stat.starting, depletionMessageShown: false}));
            });
            newData = newData.set(action.gameId, newGameData);
            break;
        }
        case ACTION_IDS.SET_STAT_VALUE: {
            const oldValue = newData.getIn([action.saveId, "stats", action.name, "value"]);
            if (oldValue === 0 && action.value > 0){
                newData = newData.setIn([action.saveId, "stats", action.name, "depletionMessageShown"], false);
            }
            newData = newData.setIn([action.saveId, "stats", action.name, "value"], action.value);
            break;
        }
        case ACTION_IDS.SET_STAT_MESSAGE_SHOWN: {
            newData = newData.setIn([action.saveId, "stats", action.name, "depletionMessageShown"], true);
            break;
        }
        case ACTION_IDS.ADD_MESSAGE: {
            newData = newData.updateIn([action.saveId, "messages"], list => (list || List()).push(action.message));
            break;
        }
        case ACTION_IDS.ADD_UNLOCKED_KEYWORD: {
            if (!newData.getIn([action.saveId, "unlockedKeywords"], []).includes(action.keyword)){
                newData = newData.updateIn([action.saveId, "unlockedKeywords"], list => (list || List()).push(action.keyword));
            }
            break;
        }
        case ACTION_IDS.REMOVE_UNLOCKED_KEYWORD: {
            newData = newData.updateIn([action.saveId, "unlockedKeywords"], list => (list || List()).filter((currentKeyword) => {
                return action.keyword !== currentKeyword;
            }));
            break;
        }
        default: {
            return state;
        }
    }
    return {data: newData}
}

export default persistentReducer;