import { useState } from "react"
import { useResizeDetector } from "react-resize-detector"
import { Typography } from "@mui/material";

export const NumericPad = (props) => {

    const [currentCode, setCode] = useState('');

    const appenCode = (digit) => {
        let newCode = currentCode + '' + digit;
        const maxDigit = props.maxDigit ? props.maxDigit : 9;
        if (newCode.length > maxDigit){
            newCode = newCode.substring(newCode.length - maxDigit);
        }
        setCode(newCode);
    }

	const { width, height, ref } = useResizeDetector();

	const getRelativeX = (absoluteX) => {
		return (absoluteX * (width ? width : 0)) / 580
	}

	const getRelativeY = (absoluteY) => {
		return (absoluteY * (height ? height : 0)) / 1000;
	}

	const keyWidth = (155 * (width ? width : 0)) / 580;

	const keyHeight = (155 * (height ? height : 0)) / 1000;

    const onCancel = () => {
        setCode('');
        props.onCancel();
    }

    const onConfirm = () => {
        const code = currentCode;
        setCode('');
        props.onConfirm(code);
    }

	const keyPositions = [
        <div key={1} style={{ position: "absolute", top: getRelativeY(234), left: getRelativeX(18), width: keyWidth, height: keyHeight }} onClick={() => appenCode(1)}/>,
        <div key={2} style={{ position: "absolute", top: getRelativeY(234), left: getRelativeX(215), width: keyWidth, height: keyHeight }} onClick={() => appenCode(2)}/>,
        <div key={3} style={{ position: "absolute", top: getRelativeY(234), left: getRelativeX(412), width: keyWidth, height: keyHeight }} onClick={() => appenCode(3)}/>,
        <div key={4} style={{ position: "absolute", top: getRelativeY(432), left: getRelativeX(18), width: keyWidth, height: keyHeight }} onClick={() => appenCode(4)}/>,
        <div key={5} style={{ position: "absolute", top: getRelativeY(432), left: getRelativeX(215), width: keyWidth, height: keyHeight }} onClick={() => appenCode(5)}/>,
        <div key={6} style={{ position: "absolute", top: getRelativeY(432), left: getRelativeX(412), width: keyWidth, height: keyHeight }} onClick={() => appenCode(6)}/>,
        <div key={7} style={{ position: "absolute", top: getRelativeY(630), left: getRelativeX(18), width: keyWidth, height: keyHeight }} onClick={() => appenCode(7)}/>,
        <div key={8} style={{ position: "absolute", top: getRelativeY(630), left: getRelativeX(215), width: keyWidth, height: keyHeight }} onClick={() => appenCode(8)}/>,
        <div key={9} style={{ position: "absolute", top: getRelativeY(630), left: getRelativeX(412), width: keyWidth, height: keyHeight }} onClick={() => appenCode(9)}/>,
        <div key={'cancel'} style={{ position: "absolute", top: getRelativeY(828), left: getRelativeX(18), width: keyWidth, height: keyHeight }} onClick={onCancel}/>,
        <div key={0} style={{ position: "absolute", top: getRelativeY(828), left: getRelativeX(215), width: keyWidth, height: keyHeight }} onClick={() => appenCode(0)}/>,
        <div key={'confirm'} style={{ position: "absolute", top: getRelativeY(828), left: getRelativeX(412), width: keyWidth, height: keyHeight }} onClick={onConfirm}/>
    ];

    const codeArea = <div key={1} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', position: "absolute", top: getRelativeY(25), left: getRelativeX(17), width: getRelativeX(550), height: getRelativeY(154) }}>
        <Typography sx={{fontSize: getRelativeY(100), color: 'white', fontFamily: 'DsDigi', marginRight: '20px'}}>{currentCode}</Typography>
    </div>

	return (
		<div style={{ width: "100%", height: "100%", display: "flex", position: "relative" }}>
			<img ref={ref} style={{ width: "100%", height: "100%" }} src={"https://github.com/dejawho/EscapeWorldAssets/blob/main/images/numericpad.png?raw=true"} alt="Numeric Pad" />
            {codeArea}
			{keyPositions}
		</div>
	)
}
