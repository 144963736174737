
const keywordFields = {
    keyword: "keyword",
    type: "type",
    blockInsert: "blockInsert",
    interactions: "interactions",
    personal: "personal",
}

const genericEventFields = {
    requiredKeywords: 'requiredKeywords',
    type: 'type',
    hidden: 'hidden',
    title: 'title',
    text: 'text',
    statsModifiers: 'statsModifiers',
    addKeyword: 'addKeyword',
    removeKeyword: 'removeKeyword',
    next: 'next',
}

const questionEventFields = {
    ...genericEventFields,
    choices: 'choices',
}

const numericpadEventFields = {
    ...genericEventFields,
    choices: 'choices',
    maxDigit: 'maxDigit',
    wrongNext: 'wrongNext',
    cancelNext: 'cancelNext',
}

const imageEventFields = {
    ...genericEventFields,
    url: 'url',
}

const audioEventFields = {
    ...genericEventFields,
    url: 'url',
}

const exchangeEvent = {
    ...genericEventFields,
    item: 'item',
}

const genericPoiFields = {
    ...genericEventFields,
    code: 'code',
    codeLabel: 'codeLabel',
    requiredKeywords: 'requiredKeywords',
    requiredRoles: 'requiredRoles',
    statsCheck: 'statsCheck',
}

const poiOnlyFields = {
    code: 'code',
    requiredKeywords: 'requiredKeywords',
    requiredRoles: 'requiredRoles',
    statsCheck: 'statsCheck',
}

const charFields = {
    label: 'label',
    description: 'description',
    id: 'id',
    icon: 'icon',
    backgroundImage: 'backgroundImage',
    stats: 'stats',
}

const charStatFields = {
    name: 'name',
    id: 'id',
    max: 'max',
    starting: 'starting',
    icon: 'icon',
    depletionEvent: 'depletionEvent',
    hidden: 'hidden',
}

const validatePoi = (campaignData, poi, messages, prefix, keywords, stats, roles) => {
    if (!poi.code) {
        messages.push(prefix + "Missing code on poi");
    }
    if (keywords.has(poi.code)){
        messages.push(prefix + `There are both a keyword and a poi with the same name ${poi.code}`);
    }
    const eventType = poi.type;
    if (!eventType){
        messages.push(prefix + `Missin type on poi ${poi.code}`);
    }
    let fieldObject;
    if (poi.type === 'message') {
        fieldObject = genericEventFields;
    } else if (poi.type === 'question') {
        fieldObject = questionEventFields;
    } else if (poi.type === 'numericpad') {
        fieldObject = numericpadEventFields;
    } else if (poi.type === 'image') {
        fieldObject = imageEventFields;
    } else if (poi.type === 'audio') {
        fieldObject = audioEventFields;
    } else if (poi.type === 'exchange') {
        fieldObject = exchangeEvent;
    } else {
        messages.push(prefix + `Unrecognized event type ${poi.type} on poi ${poi.code}`);
    }

    Object.keys(poi).forEach((poiField) => {
        if (!fieldObject[poiField] && !genericPoiFields[poiField]) {
            messages.push(prefix + "Unrecognized field " + poiField + " on poi");
        }
    })
    if (poi.requiredKeywords) {
        if (!Array.isArray(poi.requiredKeywords)) {
            messages.push(prefix + "required keywords on poi must be an array");
        } else {
            poi.requiredKeywords.forEach((requiredKeyword) => {
                if (!keywords.has(requiredKeyword)) {
                    messages.push(prefix + `Required keyword ${requiredKeyword} used in poi never decalred`);
                }
            });
        }
    }
    if (poi.requiredRoles) {
        if (!Array.isArray(poi.requiredRoles)) {
            messages.push(prefix + "required roles on poi must be an array");
        } else {
            poi.requiredRoles.forEach((requiredRole) => {
                if (!roles.has(requiredRole)) {
                    messages.push(prefix + `Required role ${requiredRole} used in poi never decalred`);
                }
            });
        }
    }
    if (poi.statsCheck) {
        if (!Array.isArray(poi.statsCheck)) {
            messages.push(prefix + "stat check on poi must be an array");
        } else {
            poi.statsCheck.forEach((statCheck) => {
                if (!statCheck.statName) {
                    messages.push(prefix + `Stat check on poi missing stat name`);
                }
                if (!stats.has(statCheck.statName)) {
                    messages.push(prefix + `Stat check ${statCheck.statName} used but never declared`);
                }
                if (statCheck.statValue === undefined) {
                    messages.push(prefix + `Stat check on ${statCheck.statName} missing statvalue`);
                }
                if (statCheck.operator) {
                    if (statCheck.operator !== '=' && statCheck.operator !== '<' && statCheck.operator !== '>' && statCheck.operator !== '<=' && statCheck.operator !== '=<' && statCheck.operator !== '>=' && statCheck.operator !== '=>') {
                        messages.push(prefix + `Stat check on ${statCheck.statName} has invalid operator ${statCheck.operator}`);
					}
                }
            });
        }
    }
    validateEvent(campaignData, poi, messages, prefix, keywords, stats, roles);
}

const validateEvent = (campaignData, event, messages, prefix, keywords, stats, roles) => {
    if (!event.type) {
        messages.push(prefix + "Missing type on event");
    } else {
        if (event.requiredKeywords){
            if (!Array.isArray(event.requiredKeywords)) {
                messages.push(prefix + "required keywords on event must be an array");
            } else {
                event.requiredKeywords.forEach((requiredKeyword) => {
                    if (!keywords.has(requiredKeyword)) {
                        messages.push(prefix + `Required keyword ${requiredKeyword} used in event never decalred`);
                    }
                });
            }
        }
        if (event.addKeyword) {
            if (Array.isArray(event.addKeyword)) {
                event.addKeyword.forEach((keyword) => {
                    if (!keywords.has(keyword)) {
                        messages.push(prefix + "Keyword added but never declared " + keyword + " on event");
                    }
                })
            } else {
                if (!keywords.has(event.addKeyword)) {
                    messages.push(prefix + "Keyword added but never declared " + event.addKeyword + " on event");
                }
            }
        }
        if (event.removeKeyword) {
            if (Array.isArray(event.removeKeyword)) {
                event.removeKeyword.forEach((keyword) => {
                    if (!keywords.has(keyword)) {
                        messages.push(prefix + "Keyword removed but never declared " + keyword + " on event");
                    }
                })
            } else {
                if (!keywords.has(event.removeKeyword)) {
                    messages.push(prefix + "Keyword removed but never declared " + event.removeKeyword + " on event");
                }
            }
            if (event.statsModifiers) {
                if (!Array.isArray(event.statsModifiers)) {
                    messages.push(prefix + "stats modifier on event must be an array");
                } else {
                    event.statsModifiers.forEach((statModifier) => {
                        if (!stats.has(statModifier.statName)) {
                            messages.push(prefix + "Stat modified but never declared " + statModifier.statName + " on event");
                        }
                    });
                }
            }
            if (event.statsModifiers) {
                if (!Array.isArray(event.statsModifiers)) {
                    messages.push(prefix + "stats modifier on event must be an array");
                } else {
                    event.statsModifiers.forEach((statModifier) => {
                        if (!stats.has(statModifier.statName)) {
                            messages.push(prefix + "Stat modified but never declared " + statModifier.statName + " on event");
                        }
                    });
                }
            }
            if (event.type === 'message') {
                Object.keys(event).forEach((eventField) => {
                    if (!genericEventFields[eventField] && !poiOnlyFields[eventField]) {
                        messages.push(prefix + "Unrecognized field " + eventField + " on message event");
                    }
                })
            } else if (event.type === 'question') {
                Object.keys(event).forEach((eventField) => {
                    if (!questionEventFields[eventField] && !poiOnlyFields[eventField]) {
                        messages.push(prefix + "Unrecognized field " + eventField + " on question event");
                    }
                })
                if (event.next){
                    messages.push(prefix + "question should not have a next event");
                }
                if (!event.choices || !Array.isArray(event.choices)) {
                    messages.push(prefix + "Invalid choices on question event");
                } else {
                    event.choices.forEach((choice, index) => {
                        if (!choice.label) {
                            messages.push(prefix + "Missing label on choice " + index);
                        }
                        if (choice.next) {
                            validateEvent(campaignData, choice.next, messages, prefix + `[choice ${index}]`, keywords, stats, roles)
                        }
                    });
                }
            } else if (event.type === 'numericpad') {
                Object.keys(event).forEach((eventField) => {
                    if (!numericpadEventFields[eventField] && !poiOnlyFields[eventField]) {
                        messages.push(prefix + "Unrecognized field " + eventField + " on numericpad event");
                    }
                })
                if (event.next){
                    messages.push(prefix + "numericpad should not have a next event");
                }
                if (!event.choices || !Array.isArray(event.choices)) {
                    messages.push(prefix + "Invalid choices on question event");
                } else {
                    event.choices.forEach((choice, index) => {
                        if (!choice.code) {
                            messages.push(prefix + "Missing code on numeric pad choice " + index);
                        }
                        if (choice.next) {
                            validateEvent(campaignData, choice.next, messages, prefix + `[numeric choice ${index}]`, keywords, stats, roles)
                        }
                    });
                }
                if (event.wrongNext) {
                    validateEvent(campaignData, event.wrongNext, messages, prefix + `[choice wrongNext]`, keywords, stats, roles)
                }
                if (event.cancelNext) {
                    validateEvent(campaignData, event.cancelNext, messages, prefix + `[choice cancelNext]`, keywords, stats, roles)
                }
            } else if (event.type === 'image') {
                Object.keys(event).forEach((eventField) => {
                    if (!imageEventFields[eventField] && !poiOnlyFields[eventField]) {
                        messages.push(prefix + "Unrecognized field " + eventField + " on image event");
                    }
                })
                if (!event.url) {
                    messages.push(prefix + "Missing url on image event");
                }
            } else if (event.type === 'audio') {
                Object.keys(event).forEach((eventField) => {
                    if (!audioEventFields[eventField] && !poiOnlyFields[eventField]) {
                        messages.push(prefix + "Unrecognized field " + eventField + " on audio event");
                    }
                })
                if (!event.url) {
                    messages.push(prefix + "Missing url on audio event");
                }
            } else if (event.type === 'exchange') {
                Object.keys(event).forEach((eventField) => {
                    if (!exchangeEvent[eventField] && !poiOnlyFields[eventField]) {
                        messages.push(prefix + "Unrecognized field " + eventField + " on exchange event");
                    }
                })
            } else {
                messages.push(prefix + `Unrecognized event type ${event.type}`);
            }
            if (event.next) {
                if (Array.isArray(event.next)){
                    event.next.forEach((singleEvent) => {
                        validateEvent(campaignData, singleEvent, messages, prefix + '[next]', keywords, stats, roles)
                    })
                } else {
                    validateEvent(campaignData, event.next, messages, prefix + '[next]', keywords, stats, roles)
                }
            }
        }
    }
}

export const validateCampaign = (campaignData, messages) => {
    const keywordsList = new Set();
    const statsList = new Set();
    const rolesList = new Set();
    campaignData.keywords.forEach((keyword, index) => {
        if (keyword.keyword) {
            if (keywordsList.has(keyword.keyword)){
                messages.push(keyword.keyword + " duplicated");
            }
            keywordsList.add(keyword.keyword);
        }
    });
    campaignData.charachters.forEach((charachter, index) => {
        if (charachter.id) {
            if (rolesList.has(charachter.id)){
                messages.push(charachter.id + " duplicated");
            }
            rolesList.add(charachter.id);
        }
        if (charachter.stats) {
            charachter.stats.forEach((stat) => {
                if (stat.id) {
                    statsList.add(stat.id);
                }
            });
        }
    });
    campaignData.charachters.forEach((charachter, index) => {
        if (!charachter.id) {
            messages.push(`Charachter ${index} is missing the id`);
        }

        if (!charachter.label) {
            messages.push(`Charachter ${index} is the label`);
        }

        if (!charachter.description) {
            messages.push(`Charachter ${index} is the description`);
        }
        if (charachter.stats) {
            charachter.stats.forEach((stat, statIndex) => {
                if (!stat.id) {
                    messages.push(`Charachter ${charachter.id} is missing a stat id on stat ${statIndex}`);
                }
                if (!stat.name) {
                    messages.push(`Charachter ${charachter.id} is missing a stat name on stat ${stat.id}`);
                }
                if (!stat.starting) {
                    messages.push(`Charachter ${charachter.id} is missing a stat starting on stat ${stat.id}`);
                }
                if (stat.depletionEvent) {
                    validateEvent(campaignData, stat.depletionEvent, messages, `[deplitionEvent on char ${charachter.id} and stat ${stat.id}]`, keywordsList, statsList, rolesList);
                }
                Object.keys(stat).forEach((statField) => {
                    if (!charStatFields[statField]) {
                        messages.push("Unrecognized field " + statField + " on charachter " + charachter.id + " stat " + stat.id);
                    }
                })
            })
        }

        Object.keys(charachter).forEach((charachterField) => {
            if (!charFields[charachterField]) {
                messages.push("Unrecognized field " + charachterField + " on charachter " + charachter.id);
            }
        })
    });
    campaignData.keywords.forEach((keyword, index) => {
        if (!keyword.keyword) {
            messages.push("Missing keyword on keywords entry " + index);
        }
        Object.keys(keyword).forEach((keywordField) => {
            if (!keywordFields[keywordField]) {
                messages.push("Unrecognized field " + keywordField + " keywords entry " + index);
            }
        })
        if (keyword.interactions) {
            keyword.interactions.forEach((interaction) => {
                if (!interaction.label) {
                    messages.push("Missing interaction label on keywords entry " + index);
                }
                if (!interaction.event) {
                    messages.push("Missing interaction event on keywords entry " + index);
                } else {
                    validateEvent(campaignData, interaction.event, messages, "[interaction event on keyword " + index + "]", keywordsList, statsList, rolesList);
                    if (interaction.event.type === "exchange" && interaction.event.item !== keyword.keyword) {
                        messages.push("[interaction event on keyword " + index + "] Exchange type mismatch " + interaction.event.item + " on event");
                    }
                }
            })
        }
    })
    campaignData.poi.forEach((poi, index) => {
        validatePoi(campaignData, poi, messages, `[poi ${index}]`, keywordsList, statsList, rolesList);
    });

}
