import { Typography } from "@mui/material"
import { useEffect, useRef } from "react"
import { useSelector } from "react-redux"
import AudioPlayer from "react-h5-audio-player"

export const MessagePanel = (props) => {
	const saveId = props.saveId
	const messagesEnd = useRef()
	const messages = useSelector((state) => state.persistentReducer.data.getIn([saveId, "messages"]))
	useEffect(() => {
		if (messagesEnd.current?.scrollIntoView) {
			messagesEnd.current?.scrollIntoView({ behavior: "smooth" })
		}
	}, [messages])
	return (
		<div style={{ display: "flex", flex: 1, flexDirection: "column", overflow: "auto" }} >
			{messages &&
				messages.map((message, index) => {
					const iskey = message.startsWith('key:');
					const isImage = message.startsWith('image:');
					const isAudio = message.startsWith('audio:');
					if (isAudio){
						return (
							<div key={index}  style={{ display: "flex", flexDirection: "row" }}>
								<span style={{ width: "30%" }} />
								<div style={{ color: "white", backgroundColor: "grey", margin: '4vw', padding: '4vw', width: '70%', fontSize: '5vw', maxWidth: '70vw' }}>
									<AudioPlayer src={message.substring(6)} showJumpControls={false}/>
								</div>
							</div >
						)
					}
					if (isImage){
						return (
							<div key={index}  style={{ display: "flex", flexDirection: "row" }}>
								<span style={{ width: "30%" }} />
								<div style={{ color: "white", backgroundColor: "grey", margin: '4vw', padding: '4vw', width: '70%', fontSize: '5vw', maxWidth: '70vw' }}>
									<img style={{ width: "100%", height: 'auto' }} src={message.substring(6)} alt="" />
								</div>
							</div >
						)
					} else if (iskey) {
						return (
							<Typography key={index} variant="h4" gutterBottom style={{ color: "white", backgroundColor: "grey", margin: '4vw', padding: '4vw', width: "40%", fontSize: '5vw', maxWidth: '50vw', wordBreak: 'break-word'}}>
								{message.substring(4)}
							</Typography>
						)
					} else {
						return (
							<div key={index}  style={{ display: "flex", flexDirection: "row" }}>
								<span style={{ width: "30%" }} />
								<Typography variant="h4" gutterBottom style={{ color: "white", backgroundColor: "grey", margin: '4vw', padding: '4vw', width: '70%', fontSize: '5vw', maxWidth: '70vw', wordWrap: 'break-word', whiteSpace: 'pre-line' }} onClick={() => {navigator.clipboard.writeText(message)}}> 
									{message}
								</Typography>
							</div >
						)
					}
				})}
                <div ref={messagesEnd}/>
		</div>
	)
}
