import { legacy_createStore as createStore, combineReducers } from "redux";
import { persistStore, persistReducer, createTransform } from "redux-persist";
import storage from "redux-persist/lib/storage";
import cacheReducer from "./reducers/cacheReducer";
import persistentReducer from "./reducers/persistentReducer";

import { fromJS } from 'immutable';

const rootReducer = combineReducers({
    persistentReducer: persistentReducer,
    cacheReducer: cacheReducer,
})

const ImmutableTransform = createTransform(
    // transform state on its way to being serialized and persisted.
    (inboundState, key) => {
      // convert an immutable to json
      let json;
      if (key === 'persistentReducer'){
        json = inboundState.data.toJS();
      }
      return json;
    },
    // transform state being rehydrated
    (outboundState, key) => {
      if (key === 'persistentReducer'){
        let immutable = fromJS(outboundState);
        return {data: immutable};
      }
    },
     // define which reducers this transform gets called for.
     { whitelist: ['persistentReducer'] },
  );
  

const persistConfig = {
    key: 'root',
    storage,
    transforms: [ImmutableTransform],
    whitelist: ['persistentReducer'],
    blacklist: ['cacheReducer'],
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer);

let persistor = persistStore(store);

export {
    store,
    persistor,
}