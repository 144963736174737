import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { Route, useLocation, Routes, Navigate } from "react-router-dom"
import { CampaignsSelector } from "./pages/CampaignsSelector"
import { MainMenu } from "./pages/MainMenu"

import { axiosGet } from "./network/axios"
import { CampaignIntro } from "./pages/CampaignIntro"
import { CharSelector } from "./pages/CharSelector"
import { Game } from "./pages/Game"
import { SaveSelector } from "./pages/SaveSelector"
import { storeCampaign, storeInCache } from "./store/actions/actions"
import { CAMPAIGNS_LIST_KEY } from "./store/reducers/cacheReducer"
import { store } from "./store/configureStore"
import { validateCampaign } from "./components/Validator"


export const downloadCampaing = async (campaignMetadataData) => {
	let campaignData = {};
	const keys = Object.keys(campaignMetadataData);
	try {
		for (let i = 0; i < keys.length; i++) {
			const key = keys[i];
			if (key.startsWith('url')) {
				const res = await axiosGet(campaignMetadataData[key]);
				campaignData = { ...campaignData, ...res.data.record }
			}
		}
		const validation = [];
		validateCampaign(campaignData, validation);
		if (validation.length > 0) {
			console.log(JSON.stringify(validation))
		}
		return campaignData;
	} catch (error) {
		throw error;
	}
}

export const Initializer = (props) => {
	const dispatch = useDispatch()
	const location = useLocation()
	useEffect(() => {
		axiosGet("https://api.jsonbin.io/v3/b/67982893e41b4d34e47fc36b")
			.then((res) => {
				const campaigns = res.data.record
				campaigns.sort(function (a, b) {
					const title1 = a.title.toLowerCase().trim()
					const title2 = b.title.toLowerCase().trim()
					if (title1 < title2) {
						return -1
					} else if (title1 > title2) {
						return 1
					} else {
						return 0
					}
				})
				dispatch(storeInCache(CAMPAIGNS_LIST_KEY, campaigns))
				if (location.pathname.startsWith("/game") || location.pathname.startsWith("/escapeworld/game")) {
					const saveId = location.pathname.startsWith("/game") ? location.pathname.substring(6) : location.pathname.substring(18)
					const state = store.getState()
					const saves = state.persistentReducer.data
					const scenarioId = saves.getIn([saveId, "scenarioId"])
					const campaignMetadata = campaigns.find((currentCampaignMetadata) => currentCampaignMetadata.id === scenarioId)
					if (campaignMetadata) {
						downloadCampaing(campaignMetadata).then((campaignData) => {
							dispatch(storeCampaign(campaignData))
						}).catch((error) => {
							console.log(error.message)
						});
					}
				}
			})
			.catch((error) => {
				console.log(error.message)
			})
	}, [])

	return (
		<Routes>
			<Route path="/" element={<MainMenu />} />
			<Route path="/escapeworld" element={<MainMenu />} />
			<Route path="campaigns" element={<CampaignsSelector />} />
			<Route path="/escapeworld/campaigns" element={<CampaignsSelector />} />

			<Route path="game/:id" element={<Game />} />
			<Route path="/escapeworld/game/:id" element={<Game />} />

			<Route path="intro/:id" element={<CampaignIntro />} />
			<Route path="/escapeworld/intro/:id" element={<CampaignIntro />} />

			<Route path="char/:id" element={<CharSelector />} />
			<Route path="/escapeworld/char/:id" element={<CharSelector />} />

			<Route path="load" element={<SaveSelector />} />
			<Route path="/escapeworld/load" element={<SaveSelector />} />
			<Route path="*" element={<Navigate to="/escapeworld" replace />} />
		</Routes>
	)
}
