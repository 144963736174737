import { useDispatch, useSelector } from "react-redux"
import { CAMPAIGNS_KEY } from "../store/reducers/cacheReducer"
import { useParams } from "react-router-dom"
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Drawer, IconButton, TextField, Typography } from "@mui/material"
import { useCallback, useEffect, useMemo, useState } from "react"
import SearchIcon from "@mui/icons-material/Search"
import { addMessage, addUnlockedKeyword, removeUnlockedKeyword, setStatMessageShown, setStatValue } from "../store/actions/actions"
import { MessagePanel } from "../components/MessagePanel"
import { Header } from "../components/Header"
import { DrawerContent } from "../components/DrawerContent"
import { Scanner } from "@yudiel/react-qr-scanner"
import QrCode2Icon from "@mui/icons-material/QrCode2"
import { NumericPad } from "../components/NumericPad"
import AudioPlayer from "react-h5-audio-player"
import QRCode from "react-qr-code";

const getValidEvent = (availableEvents, charachterData, unlockedKeywords, stats) => {
	let selectedEvent
	let specificator = 0
	if (availableEvents) {
		availableEvents.forEach((currentEvent) => {
			let isFine = true
			let specificatorFound = 0
			if (currentEvent.requiredKeywords) {
				let allKeywordFound = true;
				for (let i = 0; i < currentEvent.requiredKeywords.length && allKeywordFound; i++) {
					allKeywordFound = unlockedKeywords.findIndex((currentKeyword) => currentKeyword.trim().toLowerCase() === currentEvent.requiredKeywords[i].trim().toLowerCase()) !== -1
					if (allKeywordFound) {
						specificatorFound++
					}
				}
				isFine = allKeywordFound;
			}
			if (currentEvent.requiredRoles && isFine) {
				isFine = false;
				for (let i = 0; i < currentEvent.requiredRoles.length && !isFine; i++) {
					isFine = currentEvent.requiredRoles[i].trim().toLowerCase() === charachterData.id.trim().toLowerCase()
					if (isFine) {
						specificatorFound++
					}
				}
			}

			if (currentEvent.statsCheck && isFine) {
				for (let i = 0; i < currentEvent.statsCheck.length && isFine; i++) {
					const currentStat = currentEvent.statsCheck[i];
					let statValue = stats.getIn([currentStat.statName, 'value']);
					if (statValue === undefined) {
						const statDefinition = charachterData.stats.find((currentStat) => currentStat.statName === currentStat.id);
						statValue = statDefinition.starting;
					}
					const operator = currentStat.operator ? currentStat.operator : '=';
					if (operator === '=') {
						isFine = statValue === currentStat.statValue;
					} else if (operator === '<') {
						isFine = statValue < currentStat.statValue
					} else if (operator === '>') {
						isFine = statValue > currentStat.statValue
					} else if (operator === '<=' || operator === '=<') {
						isFine = statValue <= currentStat.statValue
					} else if (operator === '>=' || operator === '=>') {
						isFine = statValue >= currentStat.statValue
					}
				}
				if (isFine) {
					specificatorFound++
				}
			}
			if (isFine) {
				if (!selectedEvent || specificatorFound > specificator) {
					selectedEvent = currentEvent
					specificator = specificatorFound
				}
			}
		})
	}
	return selectedEvent;
}

const getDialogForEvent = (event, charachterData, unlockedKeywords, stats, setDialogContent, setStatCallback, addMessageCallback, addKeywordCallback, removeKeywordCallback) => {
	const passtrought = [charachterData, unlockedKeywords, stats, setDialogContent, setStatCallback, addMessageCallback, addKeywordCallback, removeKeywordCallback]
	let dialogActions
	if (event.statsModifiers) {
		event.statsModifiers.forEach((statModifier) => {
			const statDefinition = charachterData.stats.find((currentStat) => statModifier.statName === currentStat.id)
			const maxValue = statDefinition.max
			const currentValue = stats.getIn([statModifier.statName, "value"], statDefinition.starting)
			let newValue = currentValue + statModifier.value
			if (maxValue) {
				newValue = Math.min(newValue, maxValue)
			}
			newValue = Math.max(newValue, 0)
			addMessageCallback("Il tuo valore di " + statDefinition.name + (statModifier.value < 0 ? " scende " : " sale ") + " a " + newValue)
			setStatCallback(statModifier.statName, newValue)
		})
	}
	if (event.addKeyword) {
		addKeywordCallback(event.addKeyword)
	}
	if (event.removeKeyword) {
		removeKeywordCallback(event.addKeyword)
	}
	let dialogContent = <DialogContentText sx={{ fontSize: "3vw", whiteSpace: 'pre-line' }}>{event.text}</DialogContentText>
	if (event.type === "exchange") {
		let nextEvent
		if (event.next) {
			nextEvent = getValidEvent(Array.isArray(event.next) ? event.next : [event.next], charachterData, unlockedKeywords, stats)
		}
		dialogContent = (
			<div style={{ flex: 1, justifyContent: "center", alignItems: "center", display: "flex", flexDirection: 'column' }}>
				<span style={{marginBottom: 10, fontSize: "3vw" }}>Fai inquadrare questo codice QR al giocatore a cui vuoi dare l'oggetto. Una volta che avrà ottenuto l'oggetto premi "Scambio Completato"</span>
				<QRCode
					size={256}
					style={{ height: "auto", maxWidth: "50vh", width: "50vh" }}
					value={"exchange:" + event.item}
					viewBox={`0 0 256 256`}
				/>
			</div>
		)
		dialogActions = (
			<DialogActions>
				<Button sx={{ fontSize: "3vw" }} onClick={() => {
					addMessageCallback("Hai dato " + event.item);
					removeKeywordCallback(event.item);
					if (nextEvent) {
						setDialogContent(getDialogForEvent(nextEvent, ...passtrought));
					} else {
						setDialogContent(undefined);
					}
				}}>
					Scambio Completato
				</Button>
				<Button sx={{ fontSize: "3vw" }} onClick={() => setDialogContent(undefined)}>
					Annulla
				</Button>
			</DialogActions>
		)
	} else if (event.type === "audio") {
		let nextEvent
		dialogContent = (
			<div style={{ flex: 1, justifyContent: "center", alignItems: "stretch", display: "flex", minWidth: '65vw' }}>
				<AudioPlayer src={event.url} showJumpControls={false} />
			</div>
		)
		addMessageCallback("audio:" + event.url)
		if (event.next) {
			nextEvent = getValidEvent(Array.isArray(event.next) ? event.next : [event.next], charachterData, unlockedKeywords, stats)
		}
		if (nextEvent) {
			dialogActions = (
				<DialogActions>
					<Button sx={{ fontSize: "3vw" }} onClick={() => setDialogContent(getDialogForEvent(nextEvent, ...passtrought))}>
						Continua
					</Button>
				</DialogActions>
			)
		} else {
			dialogActions = (
				<DialogActions>
					<Button sx={{ fontSize: "3vw" }} onClick={() => setDialogContent(undefined)}>
						Chiudi
					</Button>
				</DialogActions>
			)
		}
	} else if (event.type === "numericpad") {
		if (event.text) {
			addMessageCallback(event.text)
		}
		const onCancel = () => {
			if (event.cancelNext) {
				setDialogContent(getDialogForEvent(event.cancelNext, ...passtrought))
			} else {
				setDialogContent(undefined)
			}
		}
		const onConfirm = (code) => {
			const choice = event.choices.find((choice) => choice.code == code)
			if (choice) {
				setDialogContent(getDialogForEvent(choice.next, ...passtrought))
			} else if (event.wrongNext) {
				//wrong code
				setDialogContent(getDialogForEvent(event.wrongNext, ...passtrought))
			}
		}
		dialogContent = <NumericPad maxDigit={event.maxDigit} onCancel={onCancel} onConfirm={onConfirm} />
	} else if (event.type === "image") {
		let nextEvent
		dialogContent = (
			<div style={{ flex: 1, justifyContent: "center", alignItems: "stretch", display: "flex" }}>
				<img style={{ width: "100%", height: "auto" }} src={event.url} alt={event.title} />
			</div>
		)
		addMessageCallback("image:" + event.url)
		if (event.next) {
			nextEvent = getValidEvent(Array.isArray(event.next) ? event.next : [event.next], charachterData, unlockedKeywords, stats)
		}
		if (nextEvent) {
			dialogActions = (
				<DialogActions>
					<Button sx={{ fontSize: "3vw" }} onClick={() => setDialogContent(getDialogForEvent(nextEvent, ...passtrought))}>
						Continua
					</Button>
				</DialogActions>
			)
		} else {
			dialogActions = (
				<DialogActions>
					<Button sx={{ fontSize: "3vw" }} onClick={() => setDialogContent(undefined)}>
						Chiudi
					</Button>
				</DialogActions>
			)
		}
	} else if (event.type === "message") {
		if (!event.hidden) {
			addMessageCallback(event.text)
		}
		let nextEvent
		if (event.next) {
			nextEvent = getValidEvent(Array.isArray(event.next) ? event.next : [event.next], charachterData, unlockedKeywords, stats)
		}
		if (nextEvent) {
			dialogActions = (
				<DialogActions>
					<Button sx={{ fontSize: "3vw" }} onClick={() => setDialogContent(getDialogForEvent(nextEvent, ...passtrought))}>
						Continua
					</Button>
				</DialogActions>
			)
		} else {
			dialogActions = (
				<DialogActions>
					<Button sx={{ fontSize: "3vw" }} onClick={() => setDialogContent(undefined)}>
						Chiudi
					</Button>
				</DialogActions>
			)
		}
	} else if (event.type === "question") {
		if (!event.hidden) {
			addMessageCallback(event.text)
		}
		let totalOptionsLength = 0;
		event.choices.forEach((choice) => {
			totalOptionsLength += choice.label.length;
		})
		const vertical =  (event.choices.length > 3) || totalOptionsLength > 50;
		
		dialogActions = (
			<DialogActions style={{flexDirection: vertical ? 'column' : 'row'}}>
				{event.choices.map((choice) => {
					return (
						<Button
							key={choice.label}
							sx={{ fontSize: "3vw" }}
							onClick={() => {
								if (!event.hidden) {
									addMessageCallback("key:" + choice.label)
								}
								if (choice.next) {
									setDialogContent(getDialogForEvent(choice.next, ...passtrought))
								} else {
									setDialogContent(undefined)
								}
							}}
						>
							{choice.label}
						</Button>
					)
				})}
			</DialogActions>
		)
	} else {
		addMessageCallback(event.text)
		dialogActions = (
			<DialogActions>
				<Button sx={{ fontSize: "3vw" }} onClick={() => setDialogContent(undefined)}>
					Chiudi
				</Button>
			</DialogActions>
		)
	}
	return (
		<>
			<DialogTitle>
				<Typography sx={{ fontSize: "4vw" }}>{event.title}</Typography>
			</DialogTitle>
			<DialogContent>{dialogContent}</DialogContent>
			{dialogActions}
		</>
	)
}

export const Game = (props) => {
	const params = useParams()
	const dispatch = useDispatch()
	const saveId = params.id
	const saveDataScenarioId = useSelector((state) => state.persistentReducer.data.getIn([saveId, "scenarioId"]))
	const saveDataCharachterId = useSelector((state) => state.persistentReducer.data.getIn([saveId, "charachterId"]))
	const unlockedKeywords = useSelector((state) => state.persistentReducer.data.getIn([saveId, "unlockedKeywords"]))
	const stats = useSelector((state) => state.persistentReducer.data.getIn([saveId, "stats"]))
	const campaignData = useSelector((state) => state.cacheReducer.cache.getIn([CAMPAIGNS_KEY, saveDataScenarioId]))
	const charData = useMemo(() => {
		if (campaignData) {
			return campaignData.charachters.find((listChar) => listChar.id === saveDataCharachterId)
		}
		return undefined
	}, [campaignData, saveDataCharachterId])
	const [currentText, setCurrentText] = useState("")
	const [dialogContent, setDialogContent] = useState()
	const [drawerOpen, setDrawerOpen] = useState(false)
	const [scannerOpen, setScannerOpen] = useState(false)

	const setStatValueCallback = useCallback(
		(name, value) => {
			dispatch(setStatValue(saveId, name, value))
		},
		[dispatch, saveId]
	)

	const addMessageCallback = useCallback(
		(message) => {
			dispatch(addMessage(saveId, message))
		},
		[dispatch, saveId]
	)

	const addKeywordCallback = useCallback(
		(keyword) => {
			if (Array.isArray(keyword)) {
				keyword.forEach((key) => {
					dispatch(addUnlockedKeyword(saveId, key))
				})
			} else {
				dispatch(addUnlockedKeyword(saveId, keyword))
			}
		},
		[dispatch, saveId]
	)

	const removeKeywordCallback = useCallback(
		(keyword) => {
			if (Array.isArray(keyword)) {
				keyword.forEach((key) => {
					dispatch(removeUnlockedKeyword(saveId, keyword))
				})
			} else {
				dispatch(removeUnlockedKeyword(saveId, keyword))
			}
		},
		[dispatch, saveId]
	)

	const resolverParams = useMemo(() => {
		return [charData, unlockedKeywords, stats, setDialogContent, setStatValueCallback, addMessageCallback, addKeywordCallback, removeKeywordCallback]
	}, [addKeywordCallback, addMessageCallback, charData, setStatValueCallback, removeKeywordCallback, stats, unlockedKeywords])

	useEffect(() => {
		if (stats && charData) {
			const zeroKey = stats.findKey((statData) => {
				return statData.get("value") === 0 && !statData.get("depletionMessageShown")
			})
			if (zeroKey) {
				const zeroedStat = charData.stats.find((currentStat) => currentStat.id === zeroKey)
				if (zeroedStat && zeroedStat.depletionEvent) {
					const dialogContent = getDialogForEvent(zeroedStat.depletionEvent, ...resolverParams)
					setDialogContent(dialogContent)
					dispatch(setStatMessageShown(saveId, zeroKey))
				}
			}
		}
	}, [charData, dispatch, resolverParams, saveId, stats])

	if (!saveDataScenarioId || !campaignData) {
		return null
	}

	const onTextChange = (event) => {
		setCurrentText(event.target.value)
	}

	const evaluateText = (textToEvaluate) => {
		const searchedCode = textToEvaluate.trim().toLowerCase();
		const keywordData = campaignData.keywords.find((currentKeywordData) => {
			return currentKeywordData.keyword.trim().toLowerCase() === searchedCode;
		});
		if (keywordData && !keywordData.blockInsert && !unlockedKeywords.includes(searchedCode)) {
			dispatch(addUnlockedKeyword(saveId, searchedCode))
			const dialogContent = getDialogForEvent({ type: "message", text: "Hai sbloccato gli eventi relativi alla parola chiave " + textToEvaluate.trim() }, ...resolverParams)
			setDialogContent(dialogContent)
		} else {
			const poiEvents = campaignData.poi.filter((currentPoi) => {
				return currentPoi.code === searchedCode
			})
			const eventToResolve = getValidEvent(poiEvents, charData, unlockedKeywords, stats);
			addMessageCallback("key:" + ((eventToResolve && eventToResolve.codeLabel) ? eventToResolve.codeLabel : searchedCode))
			if (eventToResolve) {
				const dialogContent = getDialogForEvent(eventToResolve, ...resolverParams)
				setDialogContent(dialogContent)
			} else {
				addMessageCallback("Nessun elemento con il codice inserito")
			}
		}
	}

	const executeEvent = (event) => {
		if (event) {
			const dialogContent = getDialogForEvent(event, ...resolverParams)
			setDialogContent(dialogContent)
		}
	}

	const onCodeInserted = () => {
		evaluateText(currentText)
	}

	const onScan = (text) => {
		if (text && text.length > 0) {
			const readText = text[0].rawValue
			//addMessageCallback("scanned object: " + JSON.stringify(text));
			//addMessageCallback("scanned code: " + readText)
			//setCurrentText(readText)
			if (readText.startsWith("exchange:")) {
				const item = readText.substring(9);
				addKeywordCallback(item)
				addMessageCallback("Ottenuto l'oggetto " + item);
			} else {
				evaluateText(readText)
			}
			setScannerOpen(false)
		}
	}

	return (
		<div style={{ display: "flex", flex: 1, alignItems: "stretch", flexDirection: "column" }}>
			<Header saveId={saveId} campaignStats={charData.stats} setDrawerOpen={setDrawerOpen} />
			<MessagePanel saveId={saveId} />
			<div className="codekeywordbox" style={{ height: "10%", display: "flex", flexDirection: "row", backgroundColor: "white" }}>
				<Box sx={{ flex: 1 }}>
					<TextField
						onChange={onTextChange}
						onKeyDown={(ev) => {
							if (ev.key === "Enter") {
								ev.preventDefault()
								onCodeInserted()
							}
						}}
						value={currentText}
						id="outlined-basic"
						placeholder="Inserisci codice elemento"
						variant="outlined"
						style={{ width: "100%", height: "100%", fontSize: "3vw" }}
						sx={{ backgroundColor: "white", color: "black", flex: 1, height: "100%" }}
					/>
				</Box>
				<IconButton color="primary" size="large" onClick={onCodeInserted}>
					<SearchIcon fontSize="inherit" sx={{ color: "black", fontSize: "5vh" }} />
				</IconButton>
				<IconButton color="primary" size="large" onClick={() => setScannerOpen(true)}>
					<QrCode2Icon fontSize="inherit" sx={{ color: "black", fontSize: "5vh" }} />
				</IconButton>
			</div>
			<Drawer sx={{ display: "flex", alignContent: "stretch", width: "40%" }} open={drawerOpen} onClose={() => setDrawerOpen(false)}>
				<DrawerContent saveId={saveId} charData={charData} setDrawerOpen={setDrawerOpen} executeEvent={executeEvent} />
			</Drawer>
			<Dialog maxWidth="xl" open={dialogContent !== undefined} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
				{dialogContent}
			</Dialog>
			<Dialog maxWidth="xl" open={scannerOpen} onClose={() => setScannerOpen(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
				<DialogContent>
					<Scanner onScan={(result) => onScan(result)} />
				</DialogContent>
			</Dialog>
		</div>
	)
}
