import { Box, Button, CircularProgress, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { CAMPAIGNS_KEY, CAMPAIGNS_LIST_KEY } from "../store/reducers/cacheReducer"
import { storeCampaign } from "../store/actions/actions"
import { useState } from "react"
import { axiosGet } from "../network/axios"
import { validateCampaign } from "../components/Validator"

export const SaveSelector = (props) => {
	const dispatch = useDispatch()
	const saves = useSelector((state) => state.persistentReducer.data)
	const campaigns = useSelector((state) => state.cacheReducer.cache.getIn([CAMPAIGNS_KEY]))
	const campaignsList = useSelector((state) => state.cacheReducer.cache.getIn([CAMPAIGNS_LIST_KEY]))
	const [loading, setLoading] = useState(false)
	const navigate = useNavigate()
	const buttons = []
	if (saves) {
		const downloadCampaing = async (save, id) => {
			if (!loading) {
				const scenarioId = save.get("scenarioId")
				if (!campaigns || !campaigns.has(scenarioId)) {
					const campaignMetadata = campaignsList.find((currentCampaignMetadata) => currentCampaignMetadata.id === scenarioId)
					if (campaignMetadata) {
						setLoading(true)
						let campaignData = {};
						const keys = Object.keys(campaignMetadata);
						try {
							for (let i = 0; i < keys.length; i++) {
								const key = keys[i];
								if (key.startsWith('url')) {
									const res = await axiosGet(campaignMetadata[key]);
									campaignData = { ...campaignData, ...res.data.record }
								}
							}
							const validation = [];
							validateCampaign(campaignData, validation);
							if (validation.length > 0) {
								console.log(JSON.stringify(validation))
							}
							dispatch(storeCampaign(campaignData))
							setLoading(false)
							navigate(`/escapeworld/game/${id}`)
						} catch (error) {
							setLoading(false)
							console.log(error.message)
						}
					}
				} else {
					navigate(`/escapeworld/game/${id}`)
				}
			}
		}

		const onSelect = (save, id) => {
			downloadCampaing(save, id)
		}

		saves.forEach((save, id) => {
			const time = new Date(save.get("time"))
			buttons.push(
				<Button key={id} variant="contained" size="extraLarge" onClick={() => onSelect(save, id)} sx={{ marginBottom: '3vh' }}>
					<Box>
						<Typography>{`${save.get("title")} - ${save.get("char")}`}</Typography>
						<Typography>{`${time.getDate()}/${time.getMonth()}/${time.getFullYear()}`}</Typography>
					</Box>
				</Button>
			)
		})
	}
	return (
		<div style={{ flex: 1, alignItems: "center", display: "flex", flexDirection: "column", overflow: 'auto', paddingTop: '2vh' }}>
			{buttons}
			{loading && (
				<div style={{ display: "flex", justifyContent: "center", alignItems: "center", position: "absolute", top: 0, left: 0, right: 0, bottom: 0, width: "100%", backgroundColor: "rgba(55, 55, 55, 0.5)" }}>
					<CircularProgress size="3rem" />
				</div>
			)}
		</div>
	)
}
