import React from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, Typography } from "@mui/material"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import { useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import KeyIcon from '@mui/icons-material/Key';
import InfoIcon from '@mui/icons-material/Info';
import { CAMPAIGNS_KEY } from '../store/reducers/cacheReducer';

const getIcon = (keywordInfo) => {
	if (keywordInfo.type === 'item') {
		return <KeyIcon fontSize="inherit" sx={{ color: "white", fontSize: "4vw" }} />
	}
	return <InfoIcon fontSize="inherit" sx={{ color: "white", fontSize: "4vw" }} />
}

export const DrawerContent = (props) => {
	const [open, setOpen] = React.useState(false);
	const handleClick = () => {
		setOpen(!open);
	};

	const saveId = props.saveId
	const [dialogOpen, setDialogOpen] = useState(false);
	const [dialogContent, setDialogContent] = useState({ content: undefined, actions: undefined });
	const charData = props.charData
	const navigate = useNavigate();
	const saveDataScenarioId = useSelector((state) => state.persistentReducer.data.getIn([saveId, "scenarioId"]))
	const campaignData = useSelector((state) => state.cacheReducer.cache.getIn([CAMPAIGNS_KEY, saveDataScenarioId]))
	const unlockedKeywords = useSelector((state) => state.persistentReducer.data.getIn([saveId, "unlockedKeywords"], []))

	const { publicKeywords, personalKeywords } = useMemo(() => {
		const publicKeywords = [];
		const personalKeywords = [];
		unlockedKeywords.forEach(currentKeyword => {
			const keywordData = campaignData.keywords.find((currentKeywordData) => {
				return currentKeywordData.keyword.trim().toLowerCase() === currentKeyword.trim().toLowerCase();
			});
			if (keywordData && keywordData.personal) {
				personalKeywords.push({
					keyword: currentKeyword,
					type: keywordData.type,
					interactions: keywordData.interactions,
				});
			} else if (keywordData) {
				publicKeywords.push({
					keyword: currentKeyword,
					type: keywordData.type,
					interactions: keywordData.interactions,
				});
			} else {
				publicKeywords.push({
					keyword: currentKeyword
				});
			}
		});
		return { publicKeywords, personalKeywords };
	}, [unlockedKeywords, campaignData.keywords])

	const closeDialog = () => {
		setDialogOpen(false);
		setDialogContent({ content: undefined, actions: undefined });
	}

	const showBackground = () => {
		setDialogContent({
			content: charData.description,
			actions: [
				<Button sx={{ fontSize: "3vw" }} onClick={closeDialog}>
					Chiudi
				</Button>
			]
		})
		setDialogOpen(true)
	}

	const handleElementClick = (element) => {
		if (element.interactions) {
			const dialogActions = element.interactions.map((choice) => {
				return (
					<Button
						key={choice.label}
						sx={{ fontSize: "3vw" }}
						onClick={() => {
							closeDialog();
							props.setDrawerOpen(false);
							props.executeEvent(choice.event)
						}}
					>
						{choice.label}
					</Button>
				)
			});
			dialogActions.push(
				<Button
					key="cancel"
					sx={{ fontSize: "3vw" }}
					onClick={() => {
						closeDialog();
					}}
				>
					Annulla
				</Button>
			)

			setDialogContent({
				content: `Azioni possibili per ${element.keyword}`,
				actions: dialogActions,
			})
			setDialogOpen(true)
		}
	}

	return (
		<Box sx={{ display: "flex", alignItems: "stretch", backgroundColor: "darkgray", flex: 1, flexDirection: "column", width: "60vw", paddingTop: "5vh" }} role="presentation">
			<div style={{ justifyContent: "center", flexDirection: "column", alignItems: "center", display: "flex" }}>
				<Typography sx={{ color: "white", fontSize: "4vw", textAlign: "center" }}>{charData.label}</Typography>
				{charData.icon && <img style={{ width: "30%", marginBottom: 20 }} src={charData.icon} alt="Logo" />}
				<Button
					variant="contained"
					size="large"
					sx={{ fontSize: "3vw" }}
					onClick={() => {
						showBackground();
					}}
				>
					Mostra Background
				</Button>
			</div>
			<Typography sx={{ color: "white", fontSize: "4vw", marginLeft: "1em", marginTop: "2vh" }}>{(!unlockedKeywords || unlockedKeywords.size === 0) ? '' : 'Elementi Sbloccati:'}</Typography>
			<List sx={{ marginLeft: "2vw" }}>
				{
					publicKeywords.map((keywordInfo, index) => (
						<ListItem key={index} disablePadding>
							<ListItemButton onClick={() => handleElementClick(keywordInfo)}>
								{getIcon(keywordInfo)}
								<Typography sx={{ color: "white", fontSize: "3vw" }}> {keywordInfo.keyword}</Typography>
							</ListItemButton>
						</ListItem>
					))
				}
				<ListItemButton onClick={handleClick}>
					<Typography sx={{ color: "white", fontSize: "4w", marginTop: "2vh" }}>Elementi Personali:</Typography>
					{open ? <ExpandLess color='white' style={{ color: 'white', fontSize: "4vh", marginTop: "2vh" }} /> : <ExpandMore color='white' style={{ color: 'white', fontSize: "4vh", marginTop: "2vh" }} />}
				</ListItemButton>
				<Collapse in={open} timeout="auto" unmountOnExit>
					<List component="div" disablePadding>
						{
							personalKeywords.map((keywordInfo, index) => (
								<ListItem key={index} sx={{ pl: 4 }}>
									<ListItemButton onClick={() => handleElementClick(keywordInfo)}>
										{getIcon(keywordInfo)}
										<Typography sx={{ color: "white", fontSize: "3vw" }}>- {keywordInfo.keyword}</Typography>
									</ListItemButton>
								</ListItem>
							))
						}
					</List>
				</Collapse>
			</List>
			<div style={{ display: 'flex', flex: 1, alignItems: "flex-end", justifyContent: "center" }}>
				<Button
					variant="contained"
					size="large"
					sx={{ fontSize: "3vw", marginBottom: "2vw", width: '90%' }}
					onClick={() => {
						navigate('/');
					}}
				>
					Esci
				</Button>
			</div>
			<Dialog maxWidth="xl" open={dialogOpen} onClose={closeDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
				<DialogContent>
					<DialogContentText sx={{ fontSize: "3vw" }}>{dialogContent.content}</DialogContentText>
				</DialogContent>
				<DialogActions>
					{dialogContent.actions}
				</DialogActions>
			</Dialog>
		</Box>
	)
}
