import {Map, fromJS} from 'immutable';
import { ACTION_IDS } from '../actions/actionTypes';

export const CAMPAIGNS_KEY = 'CAMPAIGNS';

export const CAMPAIGNS_LIST_KEY = 'CAMPAIGNS_LIST';

const initialState = {
    cache: Map(),
}

const cacheReducer = (state = initialState, action) => {
    let newData = state.cache;
    switch(action.type){
        case ACTION_IDS.STORE_IN_CACHE: {
            newData = newData.set(action.key, action.convertToImmutable ? fromJS(action.data) : action.data);
            break;
        }
        case ACTION_IDS.STORE_CAMPAIGNS: {
            newData = newData.set(CAMPAIGNS_KEY, Map(action.data));
            break;
        }
        case ACTION_IDS.STORE_CAMPAIGN: {
            const campaign = action.campaign;
            newData = newData.setIn([CAMPAIGNS_KEY, campaign.id], campaign);
            break;
        }
        default: {
            break;
        }
    }
    return {cache: newData}
}

export default cacheReducer;