import { useSelector } from "react-redux"
import { CAMPAIGNS_KEY } from "../store/reducers/cacheReducer"
import { useNavigate, useParams } from "react-router-dom"
import { Button, CircularProgress, Typography } from "@mui/material"

export const CampaignIntro = (props) => {
	const params = useParams();
    const navigate = useNavigate();
	const id = params.id
	const campaignData = useSelector((state) => state.cacheReducer.cache.getIn([CAMPAIGNS_KEY, id]))
	if (campaignData) {
		return (
			<div style={{ display: "flex", flex: 1, alignItems: "stretch", flexDirection: "column" }}>
				<div style={{ marginTop: '2vh', marginBottom: '10vh', flex: 1, maxHeight: '80vh'}}>
					<Typography variant="h1" gutterBottom sx={{color: 'white', textAlign: 'center', fontSize: '7vw'}}>
						{campaignData.title}
					</Typography>
					<div style={{ justifyContent: 'center', display: 'flex', alignItems: 'center', flex: 1, minHeight: 0}}>
						<span variant="h4" style={{width: "80%", color: 'white', marginTop: 50, fontSize: '5vw', overflow: 'auto', maxHeight: '70vh', whiteSpace: 'pre-line'}}>
							{campaignData.description}
						</span>
					</div>
				</div>
				<div style={{height: '10vh', justifyContent: 'center', display: 'flex', alignItems: 'center'}}>
					<Button
						variant="contained"
						size="extraLarge"
						onClick={() => {
							navigate(`/escapeworld/char/${id}`)
						}}
					>
						Continua
					</Button>
				</div>
			</div>
		)
	}
	return <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', fleX: 1, width: '100%'}}>
 		<CircularProgress size="3rem" />
	</div>
}
