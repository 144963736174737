import { Button, CircularProgress } from "@mui/material"
import { useNavigate } from "react-router-dom";
import { CAMPAIGNS_LIST_KEY } from "../store/reducers/cacheReducer";
import { useSelector } from "react-redux";

export const MainMenu = (props) => {
    let navigate = useNavigate();
    const scenarios = useSelector(state => state.cacheReducer.cache.get(CAMPAIGNS_LIST_KEY));
    return <div style={{flex: 1, alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column'}}>
        <Button disabled={!scenarios} variant="contained" size='extraLarge' onClick={() => { navigate("/escapeworld/campaigns");}}>New Game</Button>
        <Button disabled={!scenarios} variant="contained" size='extraLarge' style={{marginTop: 30}} onClick={() => { navigate("/escapeworld/load");}}>Load Game</Button>
        {!scenarios && <div>
            <CircularProgress size="3rem" />
        </div>}
    </div>
}