import { useDispatch, useSelector } from "react-redux"
import { CAMPAIGNS_KEY } from "../store/reducers/cacheReducer"
import { useNavigate, useParams } from "react-router-dom"
import { Box, Button, FormControl, MenuItem, Select, Typography } from "@mui/material"
import { useState } from "react"
import { store } from "../store/configureStore"
import { startGame } from "../store/actions/actions"

export const CharSelector = (props) => {
	const params = useParams()
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const scenarioId = params.id
	const scenarioData = useSelector((state) => state.cacheReducer.cache.getIn([CAMPAIGNS_KEY, scenarioId]))
	const [currentChar, setCurrentChar] = useState(scenarioData ? scenarioData.charachters[0].id : undefined)

	const handleChange = (event) => {
		setCurrentChar(event.target.value)
	}

	if (scenarioData) {
		let chartView
		if (currentChar) {
			const charData = scenarioData.charachters.find((listChar) => (listChar.id === currentChar))
			chartView = (
				<div style={{ display: "flex", alignItems: "center", width: "100%", flexDirection: "column", marginBottom: '4vh', flex: 1, overflow: 'auto'  }}>
					{charData.icon && <img style={{ width: "30%", marginBottom: 20 }} src={charData.icon} alt="Logo" />}
					<Typography variant="h4" gutterBottom style={{ color: "white", marginLeft: '10vw', marginRight: '10vw', fontSize: '5vw'}}>
						{charData.description}
					</Typography>
				</div>
			)
		}
		return (
			<div style={{ display: "flex", flex: 1, alignItems: "center", flexDirection: "column" }}>
				<Box sx={{ width: "80%", marginTop: '6vw', marginBottom: '6vh' }}>
					<Typography variant="h1" gutterBottom sx={{ color: "white", textAlign: "center", fontSize: '7vw' }}>
						Scegli il personaggio
					</Typography>
				</Box>
				<FormControl sx={{ width: "50%", marginBottom: '4vh' }}>
					<Select value={currentChar} onChange={handleChange} sx={{ backgroundColor: "white", fontSize: '3vw' }}>
						{scenarioData.charachters.map((char) => {
							return <MenuItem  sx={{ fontSize: '4vw' }} key={char.id} value={char.id}>{char.label}</MenuItem>
						})}
					</Select>
				</FormControl>
				{chartView}
				<Button
					variant="contained"
					size="extraLarge"
					disabled={!currentChar}
					sx={{ marginBottom: '3vh' }}
					onClick={() => {
						const currentData = store.getState().persistentReducer.data;
						let saveId = 0;
						while(currentData.has('save' + saveId)){
							saveId++;
						}
						dispatch(startGame('save' + saveId, scenarioData, currentChar));
						navigate(`/escapeworld/game/${'save' + saveId}`)
					}}
				>
					Inizia
				</Button>
			</div>
		)
	}
	return null
}
