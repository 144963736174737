import { ACTION_IDS } from "./actionTypes";


export const startGame = (gameId, scenarioData, charachterId) => {
    return {
        type: ACTION_IDS.START_GAME,
        gameId,
        scenarioData,
        charachterId,
    };
};

export const storeInCache = (key, data, convertToImmutable) => {
    return {
        type: ACTION_IDS.STORE_IN_CACHE,
        key,
        data,
        convertToImmutable,
    };
};

export const storeCampaigns = (data) => {
    return {
        type: ACTION_IDS.STORE_CAMPAIGNS,
        data,
    };
};

export const storeCampaign = (campaign) => {
    return {
        type: ACTION_IDS.STORE_CAMPAIGN,
        campaign,
    };
};

export const setStatValue = (saveId, name, value) => {
    return {
        type: ACTION_IDS.SET_STAT_VALUE,
        saveId,
        name,
        value,
    };
};

export const setStatMessageShown = (saveId, name) => {
    return {
        type: ACTION_IDS.SET_STAT_MESSAGE_SHOWN,
        saveId,
        name,
    };
};

export const addMessage = (saveId, message) => {
    return {
        type: ACTION_IDS.ADD_MESSAGE,
        saveId,
        message,
    };
};

export const addUnlockedKeyword = (saveId, keyword) => {
    return {
        type: ACTION_IDS.ADD_UNLOCKED_KEYWORD,
        saveId,
        keyword,
    }
}

export const removeUnlockedKeyword = (saveId, keyword) => {
    return {
        type: ACTION_IDS.REMOVE_UNLOCKED_KEYWORD,
        saveId,
        keyword,
    }
}